import moment from "moment-timezone";
import {payment_status} from "@/modules/checkin-facility/utils/mockup";

export default {
    components: {},
    data() {
        return {
            percent: 99.99,
            data: {
                current_page: 1,
                total_receivables: {}
            },
            filter: {
                credit_card_fee: 3.6,
                page: 1,
                per_page: 15,
                maintenance_fee: 1,
                order: "ASC",
                order_by: "oldest_created_at"
            },
            facilities: [],

            payment_statuses: payment_status
        }
    },
    async mounted() {


        await this.getPmsFacility();
        // await this.list({
        //     ...this.data,
        //     ...this.filter
        // });

    },
    methods: {
        async paginate(page) {

            var filter = {
                page: page
            }
            await this.list({...this.filter, ...filter});
        },
        async list(data) {

         await this.$lionheart.post('dashboard/payment/bookings', data).then((response) => {
                this.data = response.data;

            }).catch(async(err) => {

            })

        },
        formatNumber(data) {
            var number = Math.round(parseFloat(data) * 100) / 100;

            number = number.toFixed(2);
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")


        },
        async search(data) {
            this.filter = {...this.filter,...data};
            await this.list({...this.filter})
        },
        async getPmsFacility() {
            var response = await this.$lionheart.get('dashboard/service/pms-facility')
            this.facilities = response.data
            this.filter.pms_code = this.facilities[0].value.pms_code
        },
        convertTimeZone(date_time) {
            var timezone = moment.utc(date_time).tz(moment.tz.guess());
            return timezone.format('YYYY-MM-DD hh:mm:ss A');
        }
    }
}
